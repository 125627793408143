import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createReducer,
  on,
  Action,
  createFeatureSelector,
  ActionReducerMap,
  createSelector,
} from '@ngrx/store';

import * as actions from '../actions/data.actions';
import { DataModel } from '../models/data.model';

export interface DataState extends EntityState<DataModel> {
  type_loadings: { [type: string]: boolean };
  key_loadings: { [key: string]: boolean };
}

export const adapter: EntityAdapter<DataModel> = createEntityAdapter<DataModel>();

export const initialState: DataState = adapter.getInitialState({
  type_loadings: {},
  key_loadings: {},
});

const dataReducer = createReducer(
  initialState,

  // LOAD DATA BY ID OF CONCRETE TYPE
  on(actions.LoadDataByIdAction, (state, { id, dataType }) => {
    const key = dataType + '::' + id;
    const oldState = state.entities[key];

    return adapter.upsertOne(
      {
        id: key,
        data: oldState ? oldState.data : null,
        loading: true,
        error: null,
      },
      {
        ...state,
        key_loadings: { ...state.key_loadings, [key]: true },
      },
    );
  }),
  on(actions.DataByIdLoadedSuccessAction, (state, { id, dataType, data }) => {
    const key = dataType + '::' + id;
    const oldState = state.entities[key];
    return adapter.upsertOne(
      {
        id: key,
        data,
        loading: false,
        error: null,
      },
      {
        ...state,
        key_loadings: { ...state.key_loadings, [key]: false },
      },
    );
  }),
  on(actions.DataByIdLoadedErrorAction, (state, { id, dataType, error }) => {
    const key = dataType + '::' + id;
    const oldState = state.entities[key];
    return adapter.upsertOne(
      {
        id: key,
        data: oldState ? oldState.data : null,
        loading: false,
        error,
      },
      {
        ...state,
        key_loadings: { ...state.key_loadings, [key]: false },
      },
    );
  }),

  // LOAD ALL DATA BY TYPE
  on(actions.LoadAllDataAction, (state, { dataType }) => {
    const idsToUpdate = (state.ids as any[]).filter((k) => {
      return k.split('::')[0] === dataType;
    });
    return adapter.removeMany(idsToUpdate, {
      ...state,
      type_loadings: { ...state.type_loadings, [dataType]: true },
    });
    /*return adapter.updateMany(idsToUpdate.map(idToUpdate => ({
            id: idToUpdate,
            changes: {
                loading: true,
                error: null
            }
        })),
            {
                ...state,
                type_loadings: { ...state.type_loadings, [dataType]: true },
            }
        );*/
  }),
  on(actions.AllDataLoadedSuccessAction, (state, { dataType, data }) => {
    return adapter.upsertMany(
      data.map((item) => {
        const oldState = state.entities[dataType + '::' + item.id];
        return {
          id: dataType + '::' + item.id,
          loading: false,
          error: null,
          data: item,
        };
      }),
      {
        ...state,
        type_loadings: { ...state.type_loadings, [dataType]: false },
      },
    );
  }),
  on(actions.AllDataLoadedErrorAction, (state, { dataType, error }) => {
    const idsToUpdate = (state.ids as any[]).filter((k) => {
      return k.split('::')[0] === dataType;
    });
    return adapter.upsertMany(
      idsToUpdate.map((id) => {
        const oldState = state.entities[id];
        return {
          id,
          loading: false,
          error,
          data: null,
        };
      }),
      {
        ...state,
        type_loadings: { ...state.type_loadings, [dataType]: false },
      },
    );
  }),
);

export function reducer(state: DataState | undefined, action: Action) {
  return dataReducer(state, action);
}

export interface State {
  data: DataState;
}

export const reducers: ActionReducerMap<State> = {
  data: reducer,
};

export const getDataState = createFeatureSelector<State>('data');

export const getData = createSelector(getDataState, (state: State) => state.data);

const { selectAll } = adapter.getSelectors();

export const getDataByIdAndType = (id: string, dataType: string) =>
  createSelector(getData, (state: DataState) => state.entities[dataType + '::' + id]);

export const getAllDataByType = (dataType: string) =>
  createSelector(getData, (state: DataState) => {
    return {
      loading: state.type_loadings[dataType],
      data: selectAll(state).filter((x) => x.id.split('::')[0] === dataType),
    };
  });

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, concatMap, catchError } from 'rxjs/operators';

import {
  LoadDataByIdAction,
  DataByIdLoadedSuccessAction,
  DataByIdLoadedErrorAction,
  AllDataLoadedSuccessAction,
  AllDataLoadedErrorAction,
  LoadAllDataAction,
  AddDataAction,
  AddDataSuccessAction,
  AddDataFailedAction,
  EditDataAction,
  EditDataSuccessAction,
  EditDataFailedAction,
  DeleteDataAction,
  DeleteDataSuccessAction,
  DeleteDataFailedAction,
  ImportADDataAction,
  ImportADDataFailedAction,
  ImportADDataSuccessAction,
  RefreshADDataAction,
  RefreshADDataFailedAction,
  RefreshADDataSuccessAction,
  ImportDataFailedAction,
  ImportDataSuccessAction,
  ImportDataAction,
  ImportUsersDataAction,
  ImportUsersDataSuccessAction,
  ImportUsersDataFailedAction,
  DeleteUsersDataSuccessAction,
  DeleteUsersDataFailedAction,
  DeleteUsersDataAction,
  SendPinDataAction,
  SendPinDataSuccessAction,
  SendPinDataFailedAction,
  AccountEditDataAction,
  AccountEditSuccessAction,
  AccountEditDataFailedAction,
} from '../actions/data.actions';
import { DataService } from '../services';

@Injectable()
export class DataEffects {
  constructor(
    private dataService: DataService,
    private actions$: Actions,
  ) {}

  addData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddDataAction),
      concatMap((action) => {
        return this.dataService.addData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return AddDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
              data: x.data,
            });
          }),
          catchError((error) => {
            return [
              AddDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  importData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDataAction),
      concatMap((action) => {
        return this.dataService.importData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return ImportDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              ImportDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  editData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditDataAction),
      concatMap((action) => {
        return this.dataService.editData(action.id, action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return EditDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
              data: x.data,
            });
          }),
          catchError((error) => {
            return [
              EditDataFailedAction({
                id: action.id,
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDataAction),
      concatMap((action) => {
        return this.dataService.deleteData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return DeleteDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              DeleteDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  /*refreshAllDataAfterSuccessFullDelet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteDataSuccessAction),
            map(action => LoadAllDataAction({
                dataType: action.dataType
            }))
        ));*/

  loaDataById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDataByIdAction),
      concatMap((action) => {
        return this.dataService.fetchDataById(action.dataType, action.id).pipe(
          map((x) =>
            DataByIdLoadedSuccessAction({ id: action.id, dataType: action.dataType, data: x }),
          ),
          catchError((error) => {
            return [DataByIdLoadedErrorAction({ id: action.id, dataType: action.dataType, error })];
          }),
        );
      }),
    ),
  );

  loadAllDataByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAllDataAction),
      concatMap((action) => {
        return this.dataService.fetchDataByType(action.dataType).pipe(
          map((x) => {
            return AllDataLoadedSuccessAction({ dataType: action.dataType, data: x.data });
          }),
          catchError((error) => {
            return [AllDataLoadedErrorAction({ dataType: action.dataType, error })];
          }),
        );
      }),
    ),
  );

  /* ***** Active Deirectory ***** */
  importADData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportADDataAction),
      concatMap((action) => {
        return this.dataService.importADData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return ImportADDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              ImportADDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  refreshADData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshADDataAction),
      concatMap((action) => {
        return this.dataService.refreshADData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return RefreshADDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              RefreshADDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  /* ***** Import ***** */
  importUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportUsersDataAction),
      concatMap((action) => {
        return this.dataService.importUsersData(action.id, action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return ImportUsersDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              ImportUsersDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  /* ***** Import ***** */
  deleteUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUsersDataAction),
      concatMap((action) => {
        return this.dataService.deleteUsersData(action.id, action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return DeleteUsersDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              DeleteUsersDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  sendPinData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendPinDataAction),
      concatMap((action) => {
        return this.dataService.sendPinData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return SendPinDataSuccessAction({
              dataType: action.dataType,
              id: x.data.id,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              SendPinDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  accountEditData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountEditDataAction),
      concatMap((action) => {
        return this.dataService.accountEditData(action.dataType, action.payload).pipe(
          map((x) => {
            if (!x.success) {
              throw new Error(x.errorCode + ' - ' + x.errorDescription);
            }
            return AccountEditSuccessAction({
              dataType: action.dataType,
              commandId: action.commandId,
              success: true,
            });
          }),
          catchError((error) => {
            return [
              AccountEditDataFailedAction({
                dataType: action.dataType,
                error: error.message,
                commandId: action.commandId,
                success: false,
              }),
            ];
          }),
        );
      }),
    ),
  );
}
